import React, { Component, Suspense, lazy } from "react";
import { Route, NavLink, Switch, Redirect } from "react-router-dom";
import { Layout, Menu, Button, Tag } from "antd";
import preval from "preval.macro";

import AppContext from "../AppContext";
import axios from "axios";

const Courses = lazy(() => import("./course/Courses"));
const Streams = lazy(() => import("./stream/Streams"));
const Programs = lazy(() => import("./program/Programs"));
const Assessments = lazy(() => import("./assessment/Assessments"));
const MappingScales = lazy(() => import("./mappingScale/MappingScales"));
const CustomTags = lazy(() => import("./tags/CustomTags"));
const CustomRubrics = lazy(() => import("./CustomRubrics"));
const Reports = lazy(() => import("./report/Report"));
const UserRoleManager = lazy(() => import("./users/UserRoleManager"));
const Convenors = lazy(() => import("./convenor/ConvenorManager"));

class Dashboard extends Component {
  static contextType = AppContext;
  state = {
    fullScreenMode: false,
  };

  handleAdminSwitch = () => {
    const admin_access = sessionStorage.getItem("access_copy");
    const admin_refresh = sessionStorage.getItem("refresh_copy");

    sessionStorage.setItem("access", admin_access);
    sessionStorage.setItem("refresh", admin_refresh);
    sessionStorage.removeItem("access_copy");
    sessionStorage.removeItem("refresh_copy");
    axios.defaults.headers.common["Authorization"] = `Bearer ${admin_access}`;
    this.context.getUserData();
    this.props.history.push("/users");
  };

  render() {
    const { location } = this.props;
    const { fullScreenMode } = this.state;
    const {
      logout,
      isAdmin,
      permissionCheck,
      userRolePermissions,
      userRole,
      fetching,
    } = this.context;

    const IsImpersonate =
      sessionStorage.getItem("access_copy") &&
      sessionStorage.getItem("refresh_copy");

    const mappingDict = {
      SUPER_ADMIN: { label: "SUPER ADMIN", color: "red" },
      ADMIN: { label: "ADMIN", color: "orange" },
      PROGRAM_CONVENOR: { label: "PROGRAM CONVENOR", color: "blue" },
      COURSE_CONVENOR: { label: "COURSE CONVENOR", color: "geekblue" },
      STREAM_CONVENOR: { label: "STREAM CONVENORr", color: "purple" },
      GUEST: { label: "GUEST", color: "green" },
    };

    const currentKey = location.pathname.split("/")[1].toLowerCase();

    const role = mappingDict[userRole];

    return (
      <>
        {fetching ? (
          "Loading"
        ) : (
          <Layout style={{ height: "100%" }}>
            {!fullScreenMode ? (
              <Layout.Sider>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <img
                    className="logo"
                    src="https://cdn.teaching.unsw.edu.au/unswbranding/unsw_neg.png"
                    alt="UNSW Logo"
                    style={{ width: "80%", margin: 20 }}
                  />

                  <div style={{ flex: 1 }}>
                    <Menu
                      theme="dark"
                      mode="inline"
                      defaultOpenKeys={[
                        "curriculum",
                        "manage",
                        "admin",
                        "profile",
                        "mappingscales",
                      ]}
                      selectedKeys={[currentKey]}
                    >
                      <Menu.SubMenu title="Manage Curriculum" key="manage">
                        {/* {permissionCheck(userRolePermissions, "PROGRAM_VIEW") && ( */}
                        <Menu.Item key="programs">
                          <NavLink to="/programs">Programs</NavLink>
                        </Menu.Item>
                        {/* )} */}
                        {/* {permissionCheck(userRolePermissions, "STREAM_VIEW") && ( */}
                        <Menu.Item key="streams">
                          <NavLink to="/streams">Streams</NavLink>
                        </Menu.Item>
                        {/* )} */}
                        {/* {permissionCheck(userRolePermissions, "COURSE_VIEW") && ( */}
                        <Menu.Item key="courses">
                          <NavLink to="/courses">Courses</NavLink>
                        </Menu.Item>
                        {/* )} */}
                        <Menu.Item key="assessments">
                          <NavLink to="/assessments">Assessments</NavLink>
                        </Menu.Item>
                        {permissionCheck(
                          userRole,
                          userRolePermissions,
                          "RUBRIC_VIEW"
                        ) && (
                          <Menu.Item key="rubrics">
                            <NavLink to="/rubrics">Rubrics</NavLink>
                          </Menu.Item>
                        )}

                        {permissionCheck(
                          userRole,
                          userRolePermissions,
                          "REPORT_VIEW"
                        ) && (
                          <Menu.Item key="reports">
                            <NavLink to="/reports">Reports </NavLink>
                          </Menu.Item>
                        )}
                      </Menu.SubMenu>

                      <Menu.SubMenu title="Settings" key="mappingscales">
                        {permissionCheck(
                          userRole,
                          userRolePermissions,
                          "MAPPING_SCALE_VIEW"
                        ) && (
                          <Menu.Item key="mappingscales">
                            <NavLink to="/mappingscales">
                              Mapping Scales
                            </NavLink>
                          </Menu.Item>
                        )}
                        {permissionCheck(
                          userRole,
                          userRolePermissions,
                          "TAGS_VIEW"
                        ) && (
                          <Menu.Item key="tags">
                            <NavLink to="/tags">Tags</NavLink>
                          </Menu.Item>
                        )}
                        {permissionCheck(
                          userRole,
                          userRolePermissions,
                          "USER_ROLE_VIEW"
                        ) && (
                          <Menu.Item key="users">
                            <NavLink to="/users">Users & Roles</NavLink>
                          </Menu.Item>
                        )}
                        {permissionCheck(
                          userRole,
                          userRolePermissions,
                          "CONVENOR_VIEW"
                        ) && (
                          <Menu.Item key="convenors">
                            <NavLink to="/convenors">Convenors</NavLink>
                          </Menu.Item>
                        )}
                      </Menu.SubMenu>
                    </Menu>
                  </div>

                  <div
                    style={{
                      textAlign: "center",
                      color: "rgba(117, 117, 117, 0.5)",
                      margin: "20px 0",
                    }}
                  >
                    <Tag color={role.color}>{role.label}</Tag>
                  </div>
                  <Button
                    style={{
                      background: "#001529",
                      color: "white",
                    }}
                    onClick={() => this.setState({ fullScreenMode: true })}
                  >
                    Full Screen Mode
                  </Button>

                  <div
                    style={{
                      textAlign: "center",
                      color: "rgba(117, 117, 117, 0.5)",
                      margin: "20px 0",
                    }}
                  >
                    {`Build date: ${preval`
            const moment = require("moment");
            module.exports = moment().format("DD/MM/YYYY");
          `}`}
                  </div>
                </div>
              </Layout.Sider>
            ) : (
              ""
            )}
            <Layout>
              {!fullScreenMode ? (
                <Layout.Header
                  style={{ background: "#fff", padding: "0 2px", margin: 10 }}
                >
                  <h1 style={{ marginLeft: 20, textAlign: "center" }}>
                    Curriculum Map
                    {IsImpersonate ? (
                      <Button
                        onClick={this.handleAdminSwitch}
                        type="danger"
                        style={{
                          float: "right",
                          marginTop: "15px",
                          marginRight: "1%",
                        }}
                      >
                        Switch To Admin
                      </Button>
                    ) : (
                      <Button
                        type="warning"
                        icon="poweroff"
                        onClick={logout}
                        style={{
                          float: "right",
                          marginTop: "15px",
                          marginRight: "1%",
                        }}
                      >
                        Logout
                      </Button>
                    )}
                    <div
                      style={{
                        float: "right",
                        marginTop: "15px",
                        marginRight: "2%",
                      }}
                    />
                  </h1>
                </Layout.Header>
              ) : (
                <Button
                  onClick={() => this.setState({ fullScreenMode: false })}
                >
                  Exit Full Screen
                </Button>
              )}

              <Layout.Content
                style={{
                  margin: "1px 16px 0",
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <div style={{ padding: 24, background: "#fff", flex: "1" }}>
                  <Switch>
                    <Redirect exact from="/" to="/courses" />

                    <Suspense fallback={<div>Loading...</div>}>
                      <Route path="/courses" component={Courses} />
                      <Route path="/streams" component={Streams} />
                      <Route path="/programs" component={Programs} />
                      <Route path="/assessments" component={Assessments} />
                      <Route path="/rubrics" component={CustomRubrics} />
                      {isAdmin && <Route path="/reports" component={Reports} />}
                      {permissionCheck(
                        userRole,
                        userRolePermissions,
                        "MAPPING_SCALE_VIEW"
                      ) && (
                        <Route
                          path="/mappingscales"
                          component={MappingScales}
                        />
                      )}
                      {permissionCheck(
                        userRole,
                        userRolePermissions,
                        "TAGS_VIEW"
                      ) && <Route path="/tags" component={CustomTags} />}
                      {permissionCheck(
                        userRole,
                        userRolePermissions,
                        "USER_ROLE_VIEW"
                      ) && <Route path="/users" component={UserRoleManager} />}
                      {permissionCheck(
                        userRole,
                        userRolePermissions,
                        "CONVENOR_VIEW"
                      ) && <Route path="/convenors" component={Convenors} />}
                    </Suspense>
                  </Switch>
                </div>
              </Layout.Content>
            </Layout>
          </Layout>
        )}
      </>
    );
  }
}

export default Dashboard;
